/* src/styles/App.css */
body,
html,
#root {
    font-family: 'Poppins', sans-serif !important;
    margin: 0 !important;
    padding: 0 !important;
    box-sizing: border-box;
}


.ant-layout-sider-trigger {
    display: none !important;
}

/* src/styles/App.css */
body,
html,
#root {
    font-family: 'Poppins', sans-serif !important;
}

:where(.ant-card, .ant-typography, .ant-layout, .ant-layout-header, .ant-layout-sider, .ant-layout-content, .ant-layout-footer, .ant-menu, .ant-menu-item, .ant-menu-submenu, .ant-btn, .ant-badge, .ant-avatar, .ant-tooltip, .ant-table, .ant-card-body, .ant-card-head, .ant-card-meta, .ant-modal, .ant-modal-header, .ant-modal-body, .ant-modal-footer) {
    font-family: 'Poppins', sans-serif !important;
}

/* Remove column borders */
.ant-table-cell {
    border: none;
}

/* Set row borders */
.custom-row {
    border-bottom: 1px solid #444444 !important;
    /* Match the dark theme border color */
}

/* Optional: Add a border to the last row if needed */
.ant-table-footer {
    border-top: 1px solid #444444 !important;
}

.transparent-table .ant-table {
    background: transparent !important;
}

.transparent-table .ant-table-thead>tr>th,
.transparent-table .ant-table-tbody>tr>td,
.transparent-table .ant-table-tbody>tr>td.ant-table-cell-ellipsis,
.transparent-table .ant-table-summary>tr>td,
.transparent-row {
    background: transparent !important;
}

.transparent-table .ant-table-thead>tr>th {
    color: inherit;
    /* Maintain text color of the header */
}

.transparent-table .ant-table-summary>tr>td {
    color: inherit;
    /* Maintain text color of the summary row */
}

.custom-sidebar-menu .ant-menu-item-selected {
    background: #fff !important;
    color: #1f1f2f !important;
    font-weight: 600;
}

.custom-sidebar-menu .ant-menu-item-selected .ant-menu-item-content {
    color: #1f1f2f !important;
}